<template>
  <div class="user-profile-container">
    <section class="user-preferences">
      <div class="user-preferences-info">
        <div class="user-preferences-block">
          <div class="user-preferences-title">
            TOUR PREFERENCES
          </div>
          <div class="user-preferences-subtitle">
            Please add your pregnancy preferences to get personalized content, recommendations & offers.
          </div>
        </div>
        <div class="user-preferences-block">
          <div class="user-preferences-title">
            NEWSLETTER
          </div>
          <div class="user-preferences-subtitle">
            Subscribe to get the latest news & updates based on your interests.
          </div>
          <div class="user-preferences-subtitle privacy-policy">
            <vs-checkbox color="rgb(38, 187, 174)" v-model="privacyPolicyAgreement">I agree with Privacy Policy</vs-checkbox>
          </div>
        </div>
      </div>
      <profile-menu></profile-menu>
    </section>
    <section class="user-preferences user-interests">
      <div class="user-preferences-block">
        <div class="user-preferences-title">
          CHOOSE YOUR INTERESTS
        </div>
        <div class="user-preferences-subtitle">
          Click each icon that applies to you to customize your pregnancy experiences.
        </div>
        <div class="pregnancy-experiences">
          <div class="pregnancy-experience-item"
               v-for="(experience, key) in experiences" :key="key"
               @click="experience.selected = !experience.selected"
               :class="{ 'selected': experience.selected }"
          >
            <img :src="getImgUrl(experience.image)"/>
            <div class="pregnancy-experience-title">
              {{ experience.title }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import ProfileMenu from '@/components/user/menu/ProfileMenu.vue';

export default {
  data() {
    return {
      baseUrl: 'assets/images/pages/user-profile/pregnancy-experience-img',
      privacyPolicyAgreement: false,
      experiences: [
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img1.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img2.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img3.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img4.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img5.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img6.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img7.png',
          'selected': false
        },
        {
          'title': 'Nesting',
          'image': 'pregnancy-experience-img8.png',
          'selected': false
        },
      ]
    }
  },
  components: {
    ProfileMenu
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout');
    },
    getImgUrl: function (imagePath) {
      return require('@/assets/images/pages/user-profile/' + imagePath);
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.AppActiveUser
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.user-profile-container {
  max-width: 1400px;
  margin: 0 auto;
  .user-preferences {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 35px;
    .user-preferences-info {
      width: 70%;
    }
    .user-menu {
      width: 30%;
    }
    .user-preferences-block {
      .user-preferences-title {
        font-size: 24px;
        color: $green;
        font-weight: 700;
        padding-bottom: 5px;
      }
      .user-preferences-subtitle {
        font-size: 16px;
        color: $lightGreen;
        &.privacy-policy {
          margin-top: 5px;
        }
      }
      margin-bottom: 50px;
      .user-attr-name {
        font-size: 16px;
        color: $green;
        text-transform: uppercase;
        padding-bottom: 5px;
        &.password-attribute {
          margin-top: 30px;
        }
      }
      .user-attr-value {
        font-size: 16px;
        color: $lightGreen;
        text-transform: uppercase;
        padding-bottom: 5px;
      }
      .user-edit-attr-link {
        text-decoration: underline;
        color: $green;
        font-size: 16px;
      }
      .pregnancy-experiences {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .pregnancy-experience-item {
          margin-right: 20px;
          margin-top: 30px;
          width: 130px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
          .pregnancy-experience-title {
            padding-top: 10px;
            color: $lightGreen;
            font-size: 14px;
          }
          &:hover {
            cursor: pointer;
            box-shadow: 4px 4px 12px 0px rgba(34, 60, 80, 0.2);
          }
          &.selected {
            box-shadow: 4px 4px 12px 0px $lightGreen;
          }
        }
        @media (max-width: 1050px) {
          padding: 0;
          margin-right: 10px;
        }
      }
    }
  }
}

</style>
